<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"addCouponCodeMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const addCouponCodeMutation = gql`
      mutation($promoCode: String!, $token: UUID!) {
        checkoutAddPromoCode(
            promoCode: $promoCode
            token: $token
          ) {
          checkout {
            id
            discount{
              amount
            }
            discountName
            voucherCode
            totalPrice {
              gross {
                amount
                currency
              }
            }
          }
          checkoutErrors {
            field
            message
            code
          }
        }
      }
      `;
      
    const { execute: addCoupon, isFetching: addCouponIsFetching } = useMutation(addCouponCodeMutation);
  
    store.commit('graphqlData', { item: 'addCoupon', result: addCoupon });
    store.commit('graphqlData', { item: 'addCouponIsFetching', result: addCouponIsFetching });
  },
}
</script>