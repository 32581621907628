<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"removeCouponCodeMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const removeCouponCodeMutation = gql`
    
      mutation($promoCode: String!, $token: UUID!) {
        checkoutRemovePromoCode(
            promoCode: $promoCode
            token: $token
          ) {
          checkout {
            id
            discount{
              amount
            }
            totalPrice {
              gross {
                amount
                currency
              }
            }
          }
          checkoutErrors {
            field
            message
            code
          }
        }
      }
      `;
      
    const { execute: removeCoupon, isFetching: removeCouponIsFetching } = useMutation(removeCouponCodeMutation);
  
    store.commit('graphqlData', { item: 'removeCoupon', result: removeCoupon });
    store.commit('graphqlData', { item: 'removeCouponIsFetching', result: removeCouponIsFetching });
  },
}
</script>