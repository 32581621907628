<template></template>

<script>
import { useMutation, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"recheckQuantitiesQuery",
  
  watch: {
    recheckQuantitiesIsFetching() {
      this.$store.commit('graphqlData', { item: 'recheckQuantitiesIsFetching', result: this.recheckQuantitiesIsFetching });
     },
  },
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });

    const recheckQuantitiesQuery = gql`
      query($channel: String!, $ids: [ID!]!) {
        productVariants(
          first: 100, 
          channel: $channel,
          ids: $ids
        ) {
          edges {
            node {
              id
              quantityAvailable
            }
          }
        }
      }
      `;
      
    const { execute: recheckQuantities, isFetching: recheckQuantitiesIsFetching } = useMutation(recheckQuantitiesQuery);
    
    
    store.commit('graphqlData', { item: 'recheckQuantities', result: recheckQuantities });

    return { recheckQuantitiesIsFetching };
  },
}
</script>