<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"shippingAddressUpdateMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const shippingAddressUpdateMutation = gql`
      mutation($token: UUID!, $firstName: String!, $lastName: String!, $companyName: String!, $streetAddress1: String!, $streetAddress2: String, $postalCode: String!, $city: String!, $cityArea: String!, $country: CountryCode!, $countryArea: String!) {
        checkoutShippingAddressUpdate(
          shippingAddress: {
            firstName: $firstName
            lastName: $lastName
            companyName: $companyName
            streetAddress1: $streetAddress1
            streetAddress2: $streetAddress2
            city: $city
            cityArea: $cityArea
            postalCode: $postalCode
            country: $country
            countryArea: $countryArea
          }
          token: $token
        ) {
          checkout {
             availableShippingMethods {
              id
              name
              maximumDeliveryDays
              minimumDeliveryDays
              price {
                currency
                amount
              }
            }
          }
          errors {
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: updateShipping, isFetching: updateShippingIsFetching } = useMutation(shippingAddressUpdateMutation);
    
    store.commit('graphqlData', { item: 'updateShipping', result: updateShipping });
    store.commit('graphqlData', { item: 'updateShippingIsFetching', result: updateShippingIsFetching });
  },
}
</script>