<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"checkoutBillingAddressUpdate",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const checkoutBillingAddressUpdate = gql`
      mutation($token: UUID!, $firstName: String!, $lastName: String!, $companyName: String!, $streetAddress1: String!, $streetAddress2: String, $postalCode: String!, $city: String!, $cityArea: String!, $country: CountryCode!, $countryArea: String!) {
        checkoutBillingAddressUpdate(
          billingAddress: {
            firstName: $firstName
            lastName: $lastName
            companyName: $companyName
            streetAddress1: $streetAddress1
            streetAddress2: $streetAddress2
            city: $city
            cityArea: $cityArea
            postalCode: $postalCode
            country: $country
            countryArea: $countryArea
          }
          token: $token
        ) {
                  checkout {
             availableShippingMethods {
              id
              name
              maximumDeliveryDays
              minimumDeliveryDays
              price {
                currency
                amount
              }
            }
          }

          errors {
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: updateBilling, isFetching: updateBillingIsFetching } = useMutation(checkoutBillingAddressUpdate);
    
    store.commit('graphqlData', { item: 'updateBilling', result: updateBilling });
    store.commit('graphqlData', { item: 'updateBillingIsFetching', result: updateBillingIsFetching });
  },
}
</script>