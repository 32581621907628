<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"createCheckoutMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const createCheckoutMutation = gql`
      mutation($email: String!, $channel: String!,$firstName: String!, $lastName: String! $lines: [CheckoutLineInput]!, $languageCode: LanguageCodeEnum!) {
        checkoutCreate(
          input: {
            channel: $channel
            email: $email
            lines: $lines
            shippingAddress: {
              firstName: $firstName
              lastName: $lastName
              streetAddress1: "EMPTY"
              city: "EMPTY"
              postalCode: "12345"
              country: DE
            }
            billingAddress: {
              firstName: $firstName
              lastName: $lastName
              streetAddress1: "EMPTY"
              city: "EMPTY"
              postalCode: "12345"
              country: DE
            }
            languageCode: $languageCode
          }
        ) {
          checkout {
            id
            token
            totalPrice {
              gross {
                amount
              }
            }
          }
          checkoutErrors {
            field
            message
            code
          }
        }
      }
      `;
      
    const { execute: createCheckout, isFetching: createCheckoutisFetching } = useMutation(createCheckoutMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'createCheckout', result: createCheckout });
    store.commit('graphqlData', { item: 'createCheckoutisFetching', result: createCheckoutisFetching });
  },
}
</script>