<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"addressValidationQuery",
  
  watch: {
    validation() {
      this.$store.commit('graphqlData', { item: 'validation', result: this.validation });
     },
  },
    
  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });

    const addressValidationQuery = gql`
      query($code: CountryCode!) {
        addressValidationRules(countryCode: $code) {
          countryName
          allowedFields
          requiredFields
          countryAreaType
        }
      }
    `;
    
    const variables = reactive({
      code: computed(() => store.state.data.selectedCountryCode),
    });
  
    const { data: validation } = useQuery({
      query: addressValidationQuery,
      variables
    });
    
    return { validation };
  },
}
</script>