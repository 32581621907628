<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"checkoutPaymentCreateMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const checkoutPaymentCreateMutation = gql`
    mutation($token: UUID!, $gateway: String!, $gatewayToken: String!, $amount: PositiveDecimal!) {
      checkoutPaymentCreate(
        token: $token
        input: {
          gateway: $gateway
          token: $gatewayToken
          amount: $amount
        }
      ) {
        payment {
          id
          chargeStatus
        }
        paymentErrors {
          field
          message
        }
      }
    }
    `;
    
    const { execute: createPayment, isFetching: createPaymentIsFetching } = useMutation(checkoutPaymentCreateMutation);
    
    store.commit('graphqlData', { item: 'createPayment', result: createPayment });
    store.commit('graphqlData', { item: 'createPaymentIsFetching', result: createPaymentIsFetching });
  },
}
</script>