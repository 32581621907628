<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"checkoutCompleteMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const checkoutCompleteMutation = gql`
    mutation($token: UUID!) {
      checkoutComplete(
        token: $token
      ) {
        order{
          id
        }
        checkoutErrors {
          field
          message
          code
        }
      }
    }
    `;
    
    const { execute: completeCheckout, isFetching: completeCheckoutIsFetching } = useMutation(checkoutCompleteMutation);
    
    store.commit('graphqlData', { item: 'completeCheckout', result: completeCheckout });
    store.commit('graphqlData', { item: 'completeCheckoutIsFetching', result: completeCheckoutIsFetching });
  },
}
</script>