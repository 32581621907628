<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"setShippingMethodMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const setShippingMethodMutation = gql`
    mutation($token: UUID!, $shippingMethodId: ID!) {
      checkoutShippingMethodUpdate(
        token: $token
        shippingMethodId: $shippingMethodId
      ) 
      {
      checkout {
            id
            token
            shippingPrice{
              gross {
                amount
              }
            }
            totalPrice {
              gross {
                amount
                currency
              }
            }
          }
        checkoutErrors {
          field
          message
          code
        }
      }
    }
    `;
    
    const { execute: setShippingMethod, isFetching: setShippingMethodIsFetching } = useMutation(setShippingMethodMutation);
    
    store.commit('graphqlData', { item: 'setShippingMethod', result: setShippingMethod });
    store.commit('graphqlData', { item: 'setShippingMethodIsFetching', result: setShippingMethodIsFetching });
  },
}
</script>