<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"updateOrderMetadataMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const updateOrderMetadataMutation = gql`
    mutation($id: ID!, $customerEmailCC: String! $selectedService: String!, $paymentMethod: String!, $emailMarketing: String!, $acceptsGoodies: String!, $marketingMaterial: String!, $vatId: String!, $totalWithTaxes: String! ) {
      updateMetadata(
        id: $id
        input: [
        { key: "customerEmailCC", value: $customerEmailCC }
        { key: "selectedService", value: $selectedService }
        { key: "paymentMethod", value: $paymentMethod }
        { key: "emailMarketing", value: $emailMarketing }
        { key: "acceptsGoodies", value: $acceptsGoodies }
        { key: "marketingMaterial", value: $marketingMaterial }
        { key: "vatId", value: $vatId }
        { key: "totalWithTaxes", value: $totalWithTaxes }
        ]
      ) {
        metadataErrors {
          field
          code
        }
      }
    }
    `;
    
    const { execute: updataMetadata } = useMutation(updateOrderMetadataMutation);
    
    store.commit('graphqlData', { item: 'updataMetadata', result: updataMetadata });
  },
}
</script>